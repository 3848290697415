.DraftEditor-root {
  .DraftEditor-editorContainer {
    .public-DraftEditor-content {
      font-family: "Apercu Std";

      h1 {
        margin: 0;
        font-size: 3rem;
        font-weight: bold;
      }
      h2 {
        font-size: 2rem;
        font-weight: bold;
      }
      h3 {
        font-size: 1.5rem;
        font-weight: bold;
      }
      h4 {
        font-size: 1rem;
      }
      h5 {
        font-size: 0.75rem;
      }
      h6 {
        font-size: 0.5rem;
      }
    }
  }
}

.public-DraftStyleDefault-hasFocus .public-DraftEditor-content {
  user-select: none;
}

.public-DraftEditor-content[contenteditable="false"] {
  user-select: initial;
}
