@import "./styles/main.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  // outline: 1px dotted rgba(255, 0, 0, 0.3);
}

#root,
.App,
html,
body {
  height: 100%;
  width: 100%;
  font-family: "Apercu Std";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.full {
  width: 100%;
  height: 100%;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
