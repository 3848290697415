//
@font-face {
  font-family: "Apercu Std";
  src: url("../../assets/fonts/Apercu/apercu-bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Std";
  src: url("../../assets/fonts/Apercu/apercu-extrabold.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Std";
  src: url("../../assets/fonts/Apercu/apercu-extralight.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Std";
  src: url("../../assets/fonts/Apercu/apercu-regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Std";
  src: url("../../assets/fonts/Apercu/apercu-regular.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

// Caecilia LT Std
@font-face {
  font-family: "Caecilia LT Std";
  src:
    local("Caecilia LT Std"),
    url("../../assets/fonts/Caecilia/Caecilia\ LT\ Std\ 45\ Light.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Caecilia LT Std";
  src:
    local("Caecilia LT Std"),
    url("../../assets/fonts/Caecilia/Caecilia\ LT\ Std\ 46\ Light\ Italic.otf")
      format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Caecilia LT Std";
  src:
    local("Caecilia LT Std"),
    url("../../assets/fonts/Caecilia/Caecilia\ LT\ Std\ 55\ Roman.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Caecilia LT Std";
  src:
    local("Caecilia LT Std"),
    url("../../assets/fonts/Caecilia/Caecilia\ LT\ Std\ 75\ Bold.otf")
      format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Caecilia LT Std";
  src:
    local("Caecilia LT Std"),
    url("../../assets/fonts/Caecilia/Caecilia\ LT\ Std\ 76\ Bold\ Italic.otf")
      format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Caecilia LT Std";
  src:
    local("Caecilia LT Std"),
    url("../../assets/fonts/Caecilia/Caecilia\ LT\ Std\ 85\ Heavy.otf")
      format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Caecilia LT Std";
  src:
    local("Caecilia LT Std"),
    url("../../assets/fonts/Caecilia/Caecilia\ LT\ Std\ 86\ Heavy\ Italic.otf")
      format("opentype");
  font-weight: 900;
  font-style: italic;
}
